import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import Footer from './components/Footer';
import ConsentFormModal from './components/ConsentFormModal';

function App() {
  const [showConsentModal, setShowConsentModal] = useState(false);

  useEffect(() => {
    const consentGiven = localStorage.getItem('consentGiven');
    if (!consentGiven) {
      setShowConsentModal(true); // Show consent modal if user hasn't consented
    }
  }, []);

  const handleConsent = () => {
    setShowConsentModal(false); // Hide modal after consent
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Router>
        <main className="flex-grow-1">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
          </Routes>
        </main>
        <Footer />
        <ConsentFormModal 
          show={showConsentModal} 
          onHide={() => setShowConsentModal(false)} 
          onConsent={handleConsent} 
        />
      </Router>
    </div>
  );
}

export default App;
