import React from 'react';
import SearchForm from '../components/SearchForm';

const HomePage = () => {
  return (
    <div className="container mt-5 text-center">
      <div>
        <h1 className="gloomgrid-logo mb-4">Gloomgrid Explorer</h1>
        <p className="lead mb-5">
          Please use our tool responsibly.
        </p>
        <p className="mb-5">
          <strong>Note:</strong> Please be patient while we search for the username across platforms. Depending on the number of platforms and network conditions, this process may take some time. We appreciate your understanding and patience.
        </p>
        <SearchForm />
      </div>
    </div>
  );
};

export default HomePage;
