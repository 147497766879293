import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConsentFormModal = ({ show, onHide, onConsent }) => {
  const [ageConfirmed, setAgeConfirmed] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = () => {
    if (!ageConfirmed || !termsAgreed) {
      setErrorMessage('You must confirm your age and agree to the terms.');
      return;
    }

    // Save consent status to localStorage
    localStorage.setItem('consentGiven', 'true');
    onConsent(); // Notify parent component that consent has been given
  };

  return (
    <Modal show={show} onHide={onHide} centered backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Consent Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && (
          <div className="alert alert-danger">
            {errorMessage}
          </div>
        )}
        <form>
          <div className="form-check mb-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="ageConfirm"
              checked={ageConfirmed}
              onChange={(e) => setAgeConfirmed(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="ageConfirm">
              I confirm that I am 18 years old or older.
            </label>
          </div>

          <div className="form-check mb-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="termsAgree"
              checked={termsAgreed}
              onChange={(e) => setTermsAgreed(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="termsAgree">
              I agree to the <a href="/terms">Terms of Use</a> and <a href="/privacy">Privacy Policy</a>.
            </label>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConsentFormModal;
