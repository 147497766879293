// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../Footer.css';

const Footer = () => {
  return (
    <footer className="bg-dark text-light py-4 mt-auto">
      <div className="container text-center">
        <div className="row align-items-center">
          <div className="col-md-6">
            <p>&copy; {new Date().getFullYear()} Gloomgrid Explorer. All rights reserved.</p>
          </div>
          <div className="col-md-6">
            <ul className="list-inline mb-0">
              <li className="list-inline-item">
                <Link to="/terms" className="text-light">
                  Terms of Use
                </Link>
              </li>
              <li className="list-inline-item mx-2">|</li>
              <li className="list-inline-item">
                <Link to="/privacy" className="text-light">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
