import React, { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const SearchForm = () => {
  const [username, setUsername] = useState('');
  const [foundResults, setFoundResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searched, setSearched] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaVisible, setCaptchaVisible] = useState(true);
  let abortController;

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (loading && abortController) {
        abortController.abort();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [loading]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (username.trim() === '') {
      setError('Please enter a valid username.');
      return;
    }

    if (!captchaToken) {
      setError('Please complete the CAPTCHA.');
      return;
    }

    setLoading(true);
    setError(null);
    setFoundResults([]);
    setSearched(true);

    abortController = new AbortController();

    try {
      const response = await fetch('https://gloomgrid.com/api/check-username', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, captchaToken }),
        signal: abortController.signal,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      let buffer = '';
      const processStream = async () => {
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            setLoading(false);
            break;
          }

          buffer += decoder.decode(value, { stream: true });
          let lines = buffer.split('\n\n');
          buffer = lines.pop();

          for (const line of lines) {
            if (line.startsWith('data: ')) {
              const jsonString = line.replace('data: ', '');
              const result = JSON.parse(jsonString);

              if (result.complete) {
                setLoading(false);
                return;
              }

              if (result.found !== null) {
                setFoundResults((prevResults) => [...prevResults, result]);
              }
            }
          }
        }
      };

      await processStream();
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted by the user.');
      } else {
        setError('Error fetching results.');
      }
      setLoading(false);
    }
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
    setCaptchaVisible(false);
  };

  const handleCaptchaExpired = () => {
    setCaptchaToken(null);
    setCaptchaVisible(true);
  };

  const downloadResults = () => {
    if (foundResults.length === 0) {
      return;
    }

    const fileContent = foundResults
      .map((result) => `Platform: ${result.platform}, URL: ${result.url || 'Not Found'}`)
      .join('\n');

    const blob = new Blob([fileContent], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${username}_search_results.txt`;
    link.click();
  };

  return (
    <div className="container">
      <h2 className="my-4">Search for a Username</h2>
      <form onSubmit={handleSubmit}>
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Enter username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <button className="btn btn-primary" type="submit" disabled={loading}>
            {loading ? 'Searching...' : 'Search'}
          </button>
        </div>

        {captchaVisible && (
          <div className="recaptcha-wrapper">
            <ReCAPTCHA
              sitekey="6Le2yFUqAAAAAJWxEy5JRrW2Np47gLaMdNPtArk1"
              onChange={handleCaptchaChange}
              onExpired={handleCaptchaExpired}
            />
          </div>
        )}

        {loading && (
          <div>
            <p>Searching... Please be patient as this may take some time.</p>
          </div>
        )}

        {error && <p className="text-danger">{error}</p>}

        {foundResults.length > 0 && (
          <div className="mt-4">
            <h3>Search Results for "{username}":</h3>
            <table className="table">
              <thead>
                <tr>
                  <th>Platform</th>
                  <th>Profile Link</th>
                </tr>
              </thead>
              <tbody>
                {foundResults.map((result, index) => (
                  <tr key={index}>
                    <td>{result.platform}</td>
                    <td>
                      <a href={result.url} target="_blank" rel="noopener noreferrer">
                        {result.url}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <button
              className="btn btn-success mt-3"
              onClick={downloadResults}
              disabled={loading || foundResults.length === 0}
            >
              Download Results
            </button>
          </div>
        )}

        {searched && !loading && foundResults.length === 0 && (
          <p>No results found for "{username}".</p>
        )}
      </form>
    </div>
  );
};

export default SearchForm;
