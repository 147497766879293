// src/pages/PrivacyPage.js
import React from 'react';

const PrivacyPage = () => {
  return (
    <div className="container mt-5">
      <h1>Privacy Policy</h1>
      <p>Your privacy is important to us. Please read carefully how we handle your data.</p>

      <h2>Information We Collect</h2>
      <p>
        We collect personal data from a variety of sources, which may include but are not limited to, the following:
        <ul>
          <li>Information you provide directly when using our services or signing up</li>
          <li>Data collected from your activity while using our platform (e.g., cookies, browsing history, and device information)</li>
          <li>Information from third parties, public databases, or external sources</li>
        </ul>
        This data could include your username, email address, location, and other identifying information. By interacting
        with our services, you consent to the collection of this data regardless of the method through which we obtain it.
      </p>

      <h2>How We Use Your Data</h2>
      <p>
        We use your personal data to:
        <ul>
          <li>Improve the functionality and performance of our services</li>
          <li>Provide customer support and respond to inquiries</li>
          <li>Send relevant information and updates</li>
          <li>Customize user experience based on preferences</li>
        </ul>
      </p>
      <p>
        Additionally, we may use your data to generate insights, reports, or analytics to enhance our services. This includes data obtained
        from both direct interaction with our platform and third-party sources.
      </p>

      <h2>Data Sharing and Third-Party Disclosure</h2>
      <p>
        While we prioritize your privacy, we want to ensure transparency regarding the terms of service. Here’s the important part:
        <strong> by using our services, you consent to us storing, maintaining, using, and selling your data to third parties
        or private entities, regardless of how we collect that data.</strong> This data may include both information you provide
        directly to us and information that we obtain from third-party or public sources. 
      </p>
      <p>
        We may share this information with our business partners, data analytics companies, and other relevant third-party organizations
        who assist in maintaining and improving the service. This may include third parties outside of your country of residence, which
        may have different privacy laws than your jurisdiction.
      </p>

      <h2>Your Rights and Choices</h2>
      <p>
        You have the right to request access to, correct, or delete any personal data we have collected. However, by opting to delete or
        restrict the use of your data, certain features of our service may no longer be available to you.
      </p>

      <h2>Age Verification and Consent</h2>
      <p>
        To use <strong>Gloomgrid Explorer</strong>, you must verify that you are of legal age to provide consent for data processing in
        your jurisdiction. If you are a resident of the European Union (EU), <strong>you must be at least 18 years of age</strong> to
        use our services.
      </p>
      <p>
        By accepting this Privacy Policy, you confirm that you are over 18 years old or of legal age as defined by the laws of your country.
        If you are in a jurisdiction where the age of consent is higher, such as certain U.S. states, you confirm that you meet those age
        requirements by using our service.
      </p>
      <p>
        If you are under the legal age to provide consent, please discontinue using our services immediately. We reserve the right to
        terminate any accounts that do not meet these requirements.
      </p>

      <h2>Updates to This Policy</h2>
      <p>
        We may occasionally update this Privacy Policy to reflect changes in our practices or relevant regulations. It is your responsibility
        to review this document periodically for any changes.
      </p>
      <p>
        By continuing to use <strong>Gloomgrid Explorer</strong>, you acknowledge and agree to the terms outlined in this Privacy Policy.
      </p>
    </div>
  );
};

export default PrivacyPage;
