// src/pages/TermsPage.js
import React from 'react';

const TermsPage = () => {
  return (
    <div className="container mt-5">
      <h1>Terms of Use</h1>
      <p>Please read these terms carefully before using our site.</p>

      <h2>Acceptance of Terms</h2>
      <p>
        By accessing and using <strong>Gloomgrid Explorer</strong> ("we", "our", "us"), you are agreeing to be bound by the following terms and
        conditions. If you do not agree to these terms, you are prohibited from using or accessing this site or its services.
      </p>

      <h2>Services Provided</h2>
      <p>
        We provide a service that allows users to search for usernames across various platforms. While we strive for accuracy and
        availability of our services, <strong>we do not guarantee the continuous operation or accuracy</strong> of the platform, and we
        are not responsible for any consequences resulting from interruptions or errors in our services.
      </p>

      <h2>Age Requirement and Consent</h2>
      <p>
        You must verify that you are of legal age to use our services. In the European Union, you must be <strong>at least 18 years old</strong>.
        For jurisdictions that have higher age limits (such as certain U.S. states where the age of consent for data processing is higher),
        you confirm that you meet the age requirements set by your jurisdiction.
      </p>
      <p>
        By using this site, you affirm that you are of the legal age required to consent to the collection, storage, and processing of your
        personal data as outlined in our Privacy Policy. If you are under the legal age, you must discontinue use immediately.
      </p>

      <h2>User Responsibility</h2>
      <p>
        Users are responsible for ensuring that their use of the site is in compliance with local laws and regulations. Additionally, you
        agree not to:
        <ul>
          <li>Use the platform for illegal purposes, including fraud or data theft</li>
          <li>Access or attempt to access data from third-party platforms without authorization</li>
          <li>Submit false or misleading information while using the service</li>
        </ul>
      </p>

      <h2>Data Usage and Sharing</h2>
      <p>
        By using this service, you <strong>consent to the collection, storage, usage, and sale of your data to third parties or private entities</strong>, 
        as outlined in our Privacy Policy. This includes any data collected through the platform, regardless of how it was obtained.
      </p>

      <h2>Intellectual Property</h2>
      <p>
        All content, logos, trademarks, and other intellectual property on this site are owned by or licensed to us. You may not reproduce,
        distribute, or create derivative works from any content found on this site without prior written consent.
      </p>

      <h2>Termination</h2>
      <p>
        We reserve the right to terminate or suspend access to the service immediately, without prior notice or liability, for any reason
        whatsoever, including without limitation if you breach the terms.
      </p>

      <h2>Limitation of Liability</h2>
      <p>
        We are not liable for any damages that may result from the use of this site or its services. This includes, but is not limited to,
        damages resulting from errors, omissions, interruptions, delays, or data breaches. The service is provided on an "as-is" and "as
        available" basis without any warranties of any kind, either express or implied.
      </p>

      <h2>Governing Law</h2>
      <p>
        These terms are governed by and construed in accordance with the laws of the jurisdiction in which we operate. By using this site,
        you agree that any legal action or dispute arising from your use of the service will be resolved in accordance with the laws of our
        jurisdiction.
      </p>

      <h2>Changes to the Terms</h2>
      <p>
        We may update these terms from time to time. It is your responsibility to review these terms periodically for any changes. Continued
        use of the service after modifications are posted will constitute your acceptance of the new terms.
      </p>

      <h2>Contact Information</h2>
      <p>
        If you have any questions or concerns regarding these terms, please contact us through the information provided on our site.
      </p>
    </div>
  );
};

export default TermsPage;
